import React, { useState } from 'react';
import './Navbar.css';
import logo from '../../images/logo.png'

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  window.addEventListener("scroll" , handleScroll)

  const toggleNavbar = () => {
    setOpen(!open);
  };

  return (
    <nav className={`navbarr ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-logo"><img src={logo} width={"100%"} alt="" /></div>
      <div className={`navbar-links ${open ? 'open' : ''}`}>
        <a href="#about" onClick={() => setOpen(false)}>About</a>
        <a href="#company" onClick={() => setOpen(false)}>Our Company</a>
        <a href="#ideas" onClick={() => setOpen(false)}>Our Ideas</a>
        <a href="#guarantee" onClick={() => setOpen(false)}>Our Guarantee</a>
        <a href="#stratigies" onClick={() => setOpen(false)}>Our Stratigies</a>
        <a href="#popularity" onClick={() => setOpen(false)}>Our Journey</a>
        <a href="#shipping" onClick={() => setOpen(false)}>Shipping System</a>
        <a href="#Contact" onClick={() => setOpen(false)}>Contact System</a>
      </div>
      <div className="navbar-toggle" onClick={toggleNavbar}>
        {open ? 'X' : '☰'}
      </div>
    </nav>

  );
};

export default Navbar;
