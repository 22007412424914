import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Typewritter from "typewriter-effect";
import "./Home.css";
import image1 from "../../images/ecommercestore.png";
import image2 from "../../images/Digitalmarkitingagency.png";
import image3 from "../../images/HealthAndWellnassbuissness.png";
import image4 from "../../images/foodtruck .jpg";
import image5 from "../../images/onlineeducationplatform.png";
import image6 from "../../images/podcasting.png";
import { FaCheck } from "react-icons/fa";
import { FaHandshakeAngle } from "react-icons/fa6";
import { FaMoneyBillWave } from "react-icons/fa";
import CarouselComponent from "../carousel2/app";
import { RiDoubleQuotesL } from "react-icons/ri";
import { FaPhoneAlt } from "react-icons/fa";
import { SlEnvolope } from "react-icons/sl";
import { CiLocationOn } from "react-icons/ci";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import Navbar from "../navbar/Navbar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import video1 from "../../images/airplane shipping.mp4";
import video2 from "../../images/see shipping.mp4";
import video3 from "../../images/road shipping.mp4";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

const Hero = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    pauseOnHover: false,
    arrows: false,
  };

  return (
    <div>
      <div className="bg-overlay">
        <div className="hero-carousel">
          <Slider {...settings}>
            <div className="slide-of-hero">
              <div className="overlay"></div>
              <video
                src={video1}
                autoPlay
                muted
                loop
                width="100%"
                height="100%"
              />
              <div className="text-overlay">
                <h3>
                  Empowering Your Business with Seamless <br /> Global
                  Connections <span>.</span>
                </h3>
                <h1>
                  Guangzhou Sikandar International <br />
                  <span>TRADE CO.LTD</span>
                </h1>
                <p>
                  Renowned for our expertise in management consulting, we
                  provide swift air shipping, delivering your products through
                  the skies <span>.</span>
                </p>
                <div className="typeWritter">
                  <span>
                    <Typewritter
                      options={{
                        strings: [
                          "Business Adviser Consultant",
                          "International Business Marketing",
                          "Inovative Products Sourcing",
                          "Digital marketing",
                          "Entrepreneur startup business",
                          "Successful business models",
                          "Motivational Speaker",
                        ],
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="slide-of-hero">
              <div className="overlay"></div>
              <video
                src={video2}
                autoPlay
                muted
                loop
                width="100%"
                height="100%"
              />
              <div className="text-overlay">
                <h3>
                  Empowering Your Business with Seamless <br /> Global
                  Connections <span>.</span>
                </h3>
                <h1>
                  Guangzhou Sikandar International <br />
                  <span>TRADE CO.LTD</span>
                </h1>
                <p>
                  As a global leader in management consulting, we offer
                  efficient sea shipping, navigating your products across oceans{" "}
                  <span>.</span>
                </p>
                <div className="typeWritter">
                  <span>
                    <Typewritter
                      options={{
                        strings: [
                          "Business Adviser Consultant",
                          "International Business Marketing",
                          "Inovative Products Sourcing",
                          "Digital marketing",
                          "Entrepreneur startup business",
                          "Successful business models",
                          "Motivational Speaker",
                        ],
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="slide-of-hero">
              <div className="overlay"></div>
              <video
                src={video3}
                autoPlay
                muted
                loop
                width="100%"
                height="100%"
              />
              <div className="text-overlay">
                <h3>
                  Empowering Your Business with Seamless <br /> Global
                  Connections <span>.</span>
                </h3>
                <h1>
                  Guangzhou Sikandar International <br />
                  <span>TRADE CO.LTD</span>
                </h1>
                <p>
                  Recognized among the top management consulting firms, we
                  ensure reliable road shipping, transporting your products
                  overland <span>.</span>
                </p>
                <div className="typeWritter">
                  <span>
                    <Typewritter
                      options={{
                        strings: [
                          "Business Adviser Consultant",
                          "International Business Marketing",
                          "Inovative Products Sourcing",
                          "Digital marketing",
                          "Entrepreneur startup business",
                          "Successful business models",
                          "Motivational Speaker",
                        ],
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      <div className="section-4 p-3 container-fluid" id="about">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h1 className="sec-4-heading">
              About <span>Us</span>{" "}
            </h1>
            <p className="sec-4-desc">
              Hello, my name is <span>Sikandar Panjwani</span> , and I am a
              seasoned business professional with over 20 years of experience
              providing strategic advice and commercial expertise to clients
              worldwide. My areas of expertise include international trade,
              Including worldwide export, online business, and company set-up in
              China, with over two decades of experience in the information
              technology sector, I have been able to navigate complex markets,
              identify new opportunities, and develop. have established a strong
              track record of growing a global footprint, establishing a
              presence in China, or leveraging the power of e-commerce, I'm here
              to offer guidance and support, let's take your business to the
              next level. Find ways to level up!Some topics you might consider
              sharing about include:
              <ul className="sec-4-list">
                <li>Entrepreneurship opportunities in Pakistan</li>
                <li>Successful business models or case studies</li>
                <li> Industry trends and market analysis</li>
                <li>Innovative solutions or products</li>
                <li>Business challenges and how to overcome them</li>
                <li> Start-up stories and lessons learned</li>
                <li>Marketing and sales strategies</li>
                <li> E-commerce and digital business ideas</li>
                <li>
                  Small and medium-sized enterprise (SME) support and resources
                </li>
                <li>Business networking and partnership opportunities</li>
              </ul>
            </p>
          </div>
          <div className="col-md-6 col-sm-12 d-flex justify-content-center">
            <div className="section-4-card">
              <div className="sec-4-card-img width={'100%'}">
                <img
                  src={require("../../images/hero3.png")}
                  alt="Rosalina D. William"
                />
              </div>
              <div className="sec-4-card-content">
                <h2>
                  {" "}
                  <span>Sikandar</span> panjwani
                </h2>
                <h1>
                  Founder of <br />
                  <span> Guangzhou Sikandar International trade Co.ltd </span>
                </h1>
                <p>Business Consultant and Adviser</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-2 overlay-2 " id="company">
        <div className="sec-2-overlay"></div>
        <div className="container">
          <h1 className="main-heading text-center my-5">
            Boost Your Business Growth with My International organize establish
            company <br />
            <span>
              Guangzhuo Sikandar International <br />{" "}
              <span> Trade co.ltd </span>
            </span>
          </h1>
          <p className="sec-2-para-1 my-5" style={{ fontSize: 30 }}>
            China is a significant player in global trade and exports a vast
            array of products worldwide. Some of the main products that China
            exports include:
            <ol className="sec-4-list fw-bold ">
              <li style={{ fontSize: 30 }}>
                Electronics (e.g., smartphones, laptops, tablets , Phone
                Accessories )
              </li>
              <li style={{ fontSize: 30 }}>
                Machinery (e.g., industrial equipment, automotive parts)
              </li>
              <li style={{ fontSize: 30 }}> Textiles and apparel</li>
              <li style={{ fontSize: 30 }}>Footwear</li>
              <li style={{ fontSize: 30 }}>Furniture</li>
              <li style={{ fontSize: 30 }}> Home appliances</li>
              <li style={{ fontSize: 30 }}>
                Solar panels and renewable energy equipment
              </li>
              <li style={{ fontSize: 30 }}>Automotive vehicles</li>
              <li style={{ fontSize: 30 }}>Aerospace and defense products</li>
              <li style={{ fontSize: 30 }}>
                Medical equipment and pharmaceuticals
              </li>
              <li style={{ fontSize: 30 }}>Steel and metals</li>
              <li style={{ fontSize: 30 }}>Chemicals and plastics</li>
              <li style={{ fontSize: 30 }}>
                Food and beverages (e.g., tea, coffee, spices)
              </li>
              <li style={{ fontSize: 30 }}> Toys and games</li>
              <li style={{ fontSize: 30 }}>Sports equipment</li>
              <li style={{ fontSize: 30 }}>
                Small and medium-sized enterprise (SME) support and resources
              </li>
              <li style={{ fontSize: 30 }}>
                Business networking and partnership opportunities
              </li>
              <li style={{ fontSize: 30 }}>Computer Accessories</li>
            </ol>
          </p>
          <p className="sec-2-para-2 my-5" style={{ fontSize: 30 }}>
            We specialize in selling Chinese products worldwide, and we have the
            expertise to help businesses achieve their growth objectives.
            Whether you are a small business looking to establish your
            international presence or a large corporation aiming to increase
            your ROI, we have solutions tailored to your needs. Our customized
            export plans are designed to grow your business. We work closely
            with our clients, understanding their goals and objectives, and then
            develop and execute a tailored export strategy aligned with their
            vision.
          </p>
          <p className="sec-2-para-3 my-5" style={{ fontSize: 30 }}>
            We believe that international shipping is not just about selling
            products but also about building relationships with your target
            audience. We focus on providing engaging and valuable services that
            resonate with your customers and drive brand loyalty. Our
            data-driven approach ensures that our strategies continuously evolve
            and adapt to meet the needs of your business and your customers.
          </p>
          <hr />
        </div>
      </div>
      <div className="section-3 container-fluid p-5">
        <h1 className="sec-3-main-heading text-center  my-5">
          <span>Contact me</span> today to learn how my International marketing
          expertise can help boost your business growth.
        </h1>
        <p className="sec-3-para text-center my-5">
          My expertise are ready to help you take your business to the next
          level.
        </p>
        <div className="d-flex justify-content-center">
          <button className="sec-3-button px-5 py-2">Contact Now</button>
        </div>
      </div>

      <div className="section-5 container-fluid overlay-5" id="ideas">
        <div className="sec-5-overlay"></div>
        <h1 className="sec-5-main-heading text-center ">
          Business <span className="sec-5-span">Ideas</span>
        </h1>
        <h3 className="sec-5-text text-center my-3">
          Discover innovative business ideas and motivational topics to spark
          entrepreneurial growth:
        </h3>
        <div className="row d-flex justify-content-center">
          <div className="col-md-4 col-12 mb-4">
            <div className="card">
              <img
                src={image1}
                className="card-img-top"
                alt="E-commerce store for niche products"
              />
              <div className="card-body">
                <h5 className="card-title">
                  E-commerce store for niche products
                </h5>
                <p className="card-text">
                  Launch an online store specializing in exclusive items.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-4">
            <div className="card">
              <img
                src={image2}
                className="card-img-top"
                alt="Digital Marketing Agency"
              />
              <div className="card-body">
                <h5 className="card-title">Digital Marketing Agency</h5>
                <p className="card-text">
                  Provide expert digital marketing services.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-4">
            <div className="card">
              <img
                src={image3}
                className="card-img-top"
                alt="Health And Wellness Business"
              />
              <div className="card-body">
                <h5 className="card-title">Health And Wellness Business</h5>
                <p className="card-text">
                  Promote well-being and healthy lifestyles.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-4">
            <div className="card">
              <img
                src={image4}
                className="card-img-top"
                alt="Food truck or catering service"
              />
              <div className="card-body">
                <h5 className="card-title">Food truck or catering service</h5>
                <p className="card-text">
                  Explore mobile food services or personalized catering.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-4">
            <div className="card">
              <img
                src={image5}
                className="card-img-top"
                alt="Online education platform"
              />
              <div className="card-body">
                <h5 className="card-title">Online education platform</h5>
                <p className="card-text">
                  Create interactive online courses and resources.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-4">
            <div className="card">
              <img
                src={image6}
                className="card-img-top"
                alt="Podcasting or video production"
              />
              <div className="card-body">
                <h5 className="card-title">Podcasting or video production</h5>
                <p className="card-text">
                  Produce engaging audio or video content.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-6 container-fluid" id="guarantee">
        <div className="row">
          <h1 className="sec-6-main-heading mt-5">
            What I <span> Guarantee </span>
          </h1>
          <div className="sec-6-icon1 col-md-4 col-lg-4 col-12">
            <FaCheck className="my-4 fw-bold fs-1 sec-6-icon" />
            <h5>Accurate Execution</h5>
            <p className="sec-6-pera1">
              My client-centric approach ensures that your goals are our top
              priority.
            </p>
          </div>

          <div className="sec-6-icon2 col-md-4 col-lg-4 col-12">
            <FaHandshakeAngle className="my-4 fw-bold fs-1 sec-6-icon" />
            <h5>Commitment</h5>

            <p className="sec-6-pera2">
              My outmost commitment lies in delivering transformational change
              for each and every client.
            </p>
          </div>
          <div className="sec-6-icon3 col-md-4 col-lg-4 col-12">
            <FaMoneyBillWave className="my-4 fw-bold fs-1 sec-6-icon" />
            <h5>Value</h5>

            <p className="sec-6-pera3">
              My dedication to providing cost-effective investment solutions
              without compromising on quality is what sets me apart in the
              industry.
            </p>
          </div>
        </div>
      </div>
      <div className="section-7 overlay-7" id="stratigies">
        <div className="sec-7-overlay"></div>

        <h1 className="sec-7-main-heading text-center mt-5 mb-2">
          Customized Strategies for your business
        </h1>
        <p className="sec-7-text text-center">
          I listen to your needs and craft solutions that deliver results
        </p>
        <div className="d-flex justify-content-center">
          <button className="sec-7-btn text-center my-3">Contact Now</button>
        </div>
      </div>

      <div className="section-8 container-fluid" id="popularity">
        <h3 className="sec-8-main-heading text-center">
          20 Years <span>Journey</span>
        </h3>
        <p className="sec-8-text text-center">
          Twenty years of professional experience in my career journey <br />
          progressive career achievements.
        </p>
        <div className="sec-8-carousel text-center">
          <CarouselComponent />
        </div>
      </div>
      <div className="section-9 container-fluid overlay-9" id="shipping">
        <div className="sec-9-overlay"></div>
        <h1 className="sec-9-main-heading">
          Our Shipping <span>Service</span>{" "}
        </h1>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 sec-9-left-cont container">
              <p className="sec-9-left-cont-para">
                Guangzhou Sikandar International Trade Co., Ltd. is a
                first-class international trade enterprise with 20 years of
                experience. Our company exports products from China to
                destinations all around the world. We offer a variety of
                shipping methods to ensure efficient and reliable delivery,
                including by air, by sea, and by road. **Company Mission:**
                Centered on customer needs and vision, providing green and
                efficient all-round logistics solutions, continuously delivering
                time-saving, labor-saving, and worry-free superior customer
                experiences. **Corporate Philosophy:** Efficiency with a Mission
                Through Guangzhou Sikandar's continuous and dedicated efforts,
                the company's business has achieved significant growth. We have
                established solid business relationships with dozens of
                container shipping companies, domestic and foreign shippers,
                consignees, traders, insurers, and agents worldwide.
                Additionally, we maintain close contacts with ports, railways,
                airlines, customs, and inspection agencies to ensure smooth
                operations and excellent service.
              </p>
            </div>
            <div className="col-md-6 sec-9-right-cont">
              <img
                src={require("../../images/about service.jpg")}
                alt=""
                className="rounded"
              />
            </div>
          </div>
        </div>

        <div className="container-fluid ">
          <h1 className="text-center sec-9-commercial-main-heading">
            Commercial <span> Service </span>
          </h1>
          <div className="row">
            <div className="col-md-6 sec-9-commercial-left-cont">
              <p className="sec-9-commercial-left-cont-para">
                Guangzhou Sikandar International Trade Co., Ltd. offers
                comprehensive sea freight services, ensuring the safe and timely
                delivery of your goods. Our extensive network of global shipping
                partners allows us to provide flexible and cost-effective
                solutions for standard container shipping, special container
                shipping, and bulk cargo shipping. With our expertise, we handle
                all aspects of sea freight logistics, including customs
                clearance and documentation, ensuring a smooth and hassle-free
                experience for our clients.
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="rounded sec-9-commercial-img"
                src={require("../../images/commercial-by-ship.jpeg")}
                width={"100%"}
                alt=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <img
                className="rounded sec-9-commercial-img"
                width={"100%"}
                src={require("../../images/commercial-by-air.png")}
                alt=""
              />
            </div>
            <div className="col-md-6 sec-9-commercial-right-cont">
              <p className="sec-9-commercial-right-cont-para">
                For urgent and high-value shipments, our air freight services
                are the perfect choice. Guangzhou Sikandar International Trade
                Co., Ltd. leverages strategic partnerships with leading airlines
                to offer fast and reliable air transport solutions. Whether you
                need to ship small parcels or large consignments, we ensure your
                goods reach their destination quickly and safely. Our air
                freight services include door-to-door delivery, customs
                clearance, and real-time tracking, providing peace of mind and
                efficient service to meet your tight deadlines.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 sec-9-commercial-left-cont">
              <p className="sec-9-commercial-left-cont-para">
                Our road freight services are designed to provide seamless and
                efficient transportation of goods across various regions.
                Guangzhou Sikandar International Trade Co., Ltd. operates a
                fleet of well-maintained vehicles and collaborates with trusted
                logistics partners to offer flexible and reliable road transport
                solutions. We handle everything from full truckloads to
                less-than-truckload shipments, ensuring your products are
                delivered on time and in excellent condition. Our road freight
                services include route planning, cargo handling, and real-time
                tracking, ensuring smooth and efficient logistics operations.
              </p>
            </div>
            <div className="col-md-6 ">
              <img
                className="rounded sec-9-commercial-img"
                width={"100%"}
                src={require("../../images/commercial-by-road.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section-10 container-fluid bg-white" id="ideas">
        <h1 className="sec-5-main-heading text-center ">
          Get In <span className="sec-5-span">Touch</span>
        </h1>
        <div className="row d-flex justify-content-center">
          <div className="col-md-4 col-12 mb-4">
            <div className="card mt-5">
              <img
                src={require("../../images/chinaFlag+Phone.jpg")}
                className="card-img-top mt-5"
                alt="E-commerce store for niche products"
              />
              <div className="card-body">
                <h5 className="card-title">China Phone Service</h5>
                <p className="card-text">+86 136 4022 5991</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-4">
            <div className="card mt-5">
              <img
                src={require("../../images/whatsappQr.png")}
                className="card-img-top mt-5 whatsapp-qr"
                alt="Digital Marketing Agency"
              />
              <div className="card-body">
                <h5 className="card-title">Whatsapp Contact Service</h5>
                <p className="card-text">+92 302 222 5991</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 mb-4">
            <div className="card mt-5">
              <img
                src={require("../../images/wechat.png")}
                className="card-img-top mt-5 wechat-qr"
                alt="Health And Wellness Business"
              />
              <div className="card-body">
                <h5 className="card-title">WeChat Contact Service</h5>
                <p className="card-text">+92 302 222 5991</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
