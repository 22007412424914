import "./footer.css";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

function Footer() {
  const date = new Date();
  let year = date.getFullYear();
  return (
    <footer>
      <div>
        <div className="footer d-flex flex-column justify-content-center align-items-center  gap-4 p-5 footer-overlay">
          <div className="sec-foo-overlay"></div>
          <img src={require("../../images/logo.png")} alt="logo" />
          <p className="text-dark fw-bold text-center foo-text">
            Transform Your Business with Sikandar Panjwani Digital and <br />{" "}
            international marketing expertise
            <hr className="mt-4" />
          </p>
          <div className="icons m-3 d-flex">
            <a
              href="https://www.facebook.com/sikandar.punjwani"
              target="_blank"
            >
              {" "}
              <FaFacebookF className="facebook-icon-footer" />{" "}
            </a>
            <a href="https://www.tiktok.com/@sikandarpanjwani1" target="_blank">
              {" "}
              <FaTiktok className="tiktok-icon-footer" />
            </a>
            <a
              href="http://linkedin.com/in/sikandar-abdul-razzaq-12b210273"
              target="_blank"
            >
              {" "}
              <FaLinkedinIn className="linkedin-icon-footer" />
            </a>
            <a
              href="https://www.youtube.com/@sikandarpanjwani4621"
              target="_blank"
            >
              {" "}
              <FaYoutube className="youtube-icon-footer" />
            </a>
          </div>
        </div>
      </div>
      <div className="bg-secondary">
        <p
          className="text-dark fw-bold text-center p-3"
          style={{ marginBottom: "0px" }}
        >
          Copyright &copy; {year} - Sikandar Panjwani - All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
